import 'hem-ui/src/styles/base-styles.scss';

import { Button, Input, PageTitle, Text, TextColor, TextVariant } from 'hem-ui';
import QrCodeWithLogo from 'qrcode-with-logos';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { Navbar } from '../components/Navbar';
import * as styles from './Index.module.scss';

const generateQrCode = (url: string) => {
  new QrCodeWithLogo({
    content: url,
    width: 600,
    // @ts-expect-error lulz
    image: document.getElementById('image'),
    logo: {
      src: '/logo-512.png',
      borderRadius: 0,
    },
  }).toImage();
};

const Index: React.FC = (): JSX.Element => {
  const [url, setUrl] = useState('');
  const [error, setError] = useState<string | null>(null);

  function handleChange(event) {
    if (error !== null) {
      setError(null);
    }

    setUrl(event.target.value);
  }

  function handleSubmit() {
    if (url !== '') {
      generateQrCode(url);
    } else {
      setError('You must enter a URL');
    }
  }

  return (
    <>
      <Helmet>
        <title>QR code generator — Hem</title>
      </Helmet>
      <header>
        <Navbar />
      </header>
      <main className={styles.main}>
        <PageTitle
          title="QR code generator"
          description={
            <Text
              variant={TextVariant.BODY_SMALL}
              color={TextColor.BLUE}
              tag="a"
              href="https://en.wikipedia.org/wiki/QR_code"
            >
              What the heck is this?
            </Text>
          }
        />

        <div className={styles.form}>
          <Input
            placeholder="https://www.hem.com"
            autoFocus
            onChange={handleChange}
            error={error}
            type="url"
          />
          <Button onClick={handleSubmit}>Generate</Button>
        </div>

        <div className={styles.qrcode}>
          <img id="image" alt="" />
        </div>
      </main>
    </>
  );
};

export default Index;
