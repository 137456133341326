import { IconHem } from 'hem-ui';
import React from 'react';

import { inner } from './Navbar.module.scss';

export const Navbar: React.FC = (): JSX.Element => (
  <div className={inner}>
    <IconHem />
  </div>
);
