export const horunge = (str: string, words = 2): string => {
  if (!str) {
    return str;
  }
  const arr = str.trim().split(' ');
  if (arr.length > 2) {
    const lastWords = arr.slice(-words).join('&nbsp;');
    arr.splice(-words, words, lastWords);
    return arr.join(' ');
  }
  return str;
};
