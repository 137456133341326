import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import { Text, TextColor, TextVariant, TextWeight } from '../Text';
import {
  black,
  blue,
  button,
  cent,
  disabled,
  green,
  white,
} from './Button.module.scss';

type Props = {
  center?: boolean;
  color?: 'blue' | 'white' | 'black' | 'green' | 'disabled';
  to?: string;
};

function getTextColor(
  color: 'blue' | 'white' | 'black' | 'green' | 'disabled',
) {
  switch (color) {
    case 'blue':
    case 'black':
    case 'green':
    case 'disabled':
      return TextColor.WHITE;
    case 'white':
      return TextColor.BLACK;
  }
}

export const Button: React.FC<
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  center = false,
  color = 'blue',
  children,
  to,
  className,
  ...rest
}): JSX.Element => {
  if (to !== undefined) {
    // This is a link that _looks_ like button.
    // Questionalble, but ok, here we go.

    // External?
    if (to.startsWith('http')) {
      return (
        <a
          className={classNames(button, className, {
            [cent]: center,
            [blue]: color === 'blue',
            [white]: color === 'white',
            [black]: color === 'black',
            [green]: color === 'green',
            [disabled]: color === 'disabled',
          })}
          href={to}
          {...rest}
        >
          <Text
            variant={TextVariant.BODY_SMALL}
            weight={TextWeight.BOLD}
            color={getTextColor(color)}
          >
            {children}
          </Text>
        </a>
      );
    }

    return (
      <Link
        to={to}
        className={classNames(button, className, {
          [cent]: center,
          [blue]: color === 'blue',
          [white]: color === 'white',
          [black]: color === 'black',
          [green]: color === 'green',
          [disabled]: color === 'disabled',
        })}
        {...rest}
      >
        <Text
          variant={TextVariant.BODY_SMALL}
          weight={TextWeight.BOLD}
          color={getTextColor(color)}
        >
          {children}
        </Text>
      </Link>
    );
  }

  return (
    <button
      className={classNames(button, className, {
        [cent]: center,
        [blue]: color === 'blue',
        [white]: color === 'white',
        [black]: color === 'black',
        [green]: color === 'green',
        [disabled]: color === 'disabled',
      })}
      {...rest}
    >
      <Text
        variant={TextVariant.BODY_SMALL}
        weight={TextWeight.BOLD}
        color={getTextColor(color)}
      >
        {children}
      </Text>
    </button>
  );
};
