import React from 'react';

export const IconHem: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg width={40} height={14} fill="none" {...props}>
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M36.35 3.268c-1.543 0-2.566.702-3.267 1.744-.602-1.082-1.725-1.744-3.128-1.744-1.243 0-2.286.582-2.847 1.564V3.529h-2.426v10.105h2.506V7.82c0-1.344.862-2.226 1.985-2.226 1.163 0 1.904.842 1.904 2.185v5.855h2.527V7.82c0-1.344.842-2.226 1.985-2.226 1.183 0 1.905.842 1.905 2.185v5.855H40V7.078c0-2.246-1.664-3.81-3.65-3.81zM18.186 5.373c1.444 0 2.326.782 2.647 2.066h-5.313c.34-1.263 1.303-2.066 2.666-2.066zm5.153 3.048c0-3.068-2.165-5.173-5.173-5.173-3.007 0-5.193 2.386-5.193 5.333 0 2.968 2.246 5.354 5.374 5.354 2.085 0 3.73-.963 4.631-2.386l-1.965-1.243c-.5.822-1.423 1.403-2.646 1.403-1.464 0-2.607-.922-2.907-2.386h7.799c.04-.16.08-.561.08-.902zM8.922 0v5.434H2.707V0H0v13.634h2.707V7.86h6.215v5.774h2.707V0H8.922z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h40v13.935H0z" />
      </clipPath>
    </defs>
  </svg>
);
