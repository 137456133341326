import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import * as styles from './Text.module.scss';
import typographer from './typographer';

export enum TextVariant {
  HEADLINE_DISPLAY,
  HEADLINE_LARGE,
  HEADLINE_SMALL,
  HEADLINE_SECTION,
  TITLE,
  BODY,
  BODY_NUMERIC,
  TITLE_SMALL,
  BODY_SMALL,
  BODY_NUMERIC_SMALL,
  TITLE_X_SMALL,
  BODY_X_SMALL,
  BODY_NUMERIC_X_SMALL,
  LABEL,
}

export enum TextColor {
  WHITE,
  BLACK,
  RED,
  BLUE,
  BLUE_DARK,
  BLUE_LIGHT,
  GREEN,
  GRAY_LIGHT,
  GRAY,
  GRAY_DARK,
}

export enum TextWeight {
  NORMAL,
  BOLD,
}

type Props = {
  tag?: keyof JSX.IntrinsicElements;
  variant?: TextVariant;
  color?: TextColor;
  weight?: TextWeight;
  className?: string;
  [x: string]: unknown;
};

const getVariantClassName = (variant: TextVariant) => {
  switch (variant) {
    case TextVariant.HEADLINE_DISPLAY:
      return styles.headlineDisplay;
    case TextVariant.HEADLINE_LARGE:
      return styles.headlineLarge;
    case TextVariant.HEADLINE_SMALL:
      return styles.headlineSmall;
    case TextVariant.HEADLINE_SECTION:
      return styles.headlineSection;
    case TextVariant.TITLE:
      return styles.title;
    case TextVariant.BODY_NUMERIC:
      return styles.bodyNumeric;
    case TextVariant.TITLE_SMALL:
      return styles.titleSmall;
    case TextVariant.BODY_SMALL:
      return styles.bodySmall;
    case TextVariant.BODY_NUMERIC_SMALL:
      return styles.bodyNumericSmall;
    case TextVariant.TITLE_X_SMALL:
      return styles.titleXSmall;
    case TextVariant.BODY_X_SMALL:
      return styles.bodyXSmall;
    case TextVariant.BODY_NUMERIC_X_SMALL:
      return styles.bodyNumericXSmall;
    case TextVariant.LABEL:
      return styles.label;
    case TextVariant.BODY:
    default:
      return styles.body;
  }
};

const getColorClassName = (color: TextColor) => {
  switch (color) {
    case TextColor.WHITE:
      return styles.white;
    case TextColor.RED:
      return styles.red;
    case TextColor.BLUE_LIGHT:
      return styles.blueLight;
    case TextColor.BLUE:
      return styles.blue;
    case TextColor.BLUE_DARK:
      return styles.blueDark;
    case TextColor.GREEN:
      return styles.green;
    case TextColor.GRAY_LIGHT:
      return styles.grayLight;
    case TextColor.GRAY:
      return styles.gray;
    case TextColor.GRAY_DARK:
      return styles.grayDark;
    case TextColor.BLACK:
    default:
      return styles.black;
  }
};

const getWeightClassName = (weight: TextWeight | undefined) => {
  switch (weight) {
    case TextWeight.NORMAL:
      return styles.weight400;
    case TextWeight.BOLD:
      return styles.weight500;
    default:
      return undefined;
  }
};

export const Text: React.FC<Props> = ({
  tag = 'span',
  variant = TextVariant.BODY,
  color = TextColor.BLACK,
  weight,
  className,
  children,
  ...rest
}): JSX.Element => {
  const Tag = tag;
  const text = React.Children.map(children, (child) => {
    if (_.isString(child)) {
      return typographer(child, { locale: 'en-us' });
    }
    return child;
  });

  return (
    <Tag
      className={classNames(
        getVariantClassName(variant),
        getColorClassName(color),
        getWeightClassName(weight),
        className,
      )}
      {...rest}
    >
      {text}
    </Tag>
  );
};
