import _ from 'lodash';
import React from 'react';

import { horunge } from '../../../../gatsby-theme-hem-store/src/utils/horunge';
import { Text, TextColor, TextVariant, TextWeight } from '../Text';
import {
  css__bottom,
  css__description,
  css__subtitle,
  css__title,
  css__top,
  css__wrapper,
} from './PageTitle.module.scss';

type Props = {
  color?: TextColor;
  topTitle?: string;
  topTitleColor?: TextColor;
  title: string;
  titleTag?: keyof JSX.IntrinsicElements;
  titleVariant?: TextVariant;
  subtitle?: string;
  subtitleVariant?: TextVariant;
  description?: string | JSX.Element;
  descriptionVariant?: TextVariant;
  descriptionStyle?: React.CSSProperties;
  bottom?: JSX.Element;
};

export const PageTitle: React.FC<Props> = ({
  color = TextColor.BLACK,
  topTitle,
  topTitleColor = TextColor.GRAY_DARK,
  title,
  titleTag = 'h1',
  titleVariant = TextVariant.HEADLINE_SMALL,
  subtitle,
  subtitleVariant = TextVariant.BODY_SMALL,
  description,
  descriptionVariant = TextVariant.BODY_SMALL,
  descriptionStyle,
  bottom,
}): JSX.Element => {
  return (
    <div className={css__wrapper}>
      {!!topTitle && (
        <Text
          tag="div"
          className={css__top}
          variant={TextVariant.BODY_SMALL}
          color={topTitleColor}
          weight={TextWeight.BOLD}
        >
          {topTitle}
        </Text>
      )}
      <Text
        tag={titleTag}
        className={css__title}
        variant={titleVariant}
        color={color}
        dangerouslySetInnerHTML={{ __html: horunge(title) }}
      />
      {!!subtitle && (
        <Text
          className={css__subtitle}
          tag="p"
          variant={subtitleVariant}
          color={color}
          dangerouslySetInnerHTML={{ __html: horunge(subtitle) }}
        />
      )}
      {!!description && _.isString(description) && (
        <Text
          className={css__description}
          tag="p"
          variant={descriptionVariant}
          color={color}
          dangerouslySetInnerHTML={{ __html: horunge(description) }}
          style={descriptionStyle}
        />
      )}
      {!!description && !_.isString(description) && (
        <Text
          className={css__description}
          tag="p"
          variant={descriptionVariant}
          color={color}
          style={descriptionStyle}
        >
          {description}
        </Text>
      )}
      {!!bottom && <div className={css__bottom}>{bottom}</div>}
    </div>
  );
};
