import _ from 'lodash';
import apostrophes from 'typographic-apostrophes';
import apostrophesForPlurals from 'typographic-apostrophes-for-possessive-plurals';
import ellipses from 'typographic-ellipses';
import emdashes from 'typographic-em-dashes';
import endashes from 'typographic-en-dashes';
import quotes from 'typographic-quotes';
import singleSpaces from 'typographic-single-spaces';

export default _.flowRight(
  // order is important!
  singleSpaces,
  ellipses,
  endashes,
  emdashes,
  apostrophes,
  apostrophesForPlurals,
  quotes,
);
