// extracted by mini-css-extract-plugin
export var headlineDisplay = "Text-module--headlineDisplay--+wyZL";
export var headlineLarge = "Text-module--headlineLarge--bzrNF";
export var headlineSmall = "Text-module--headlineSmall--u12S5";
export var headlineSection = "Text-module--headlineSection--bqgOX";
export var title = "Text-module--title--NxM5P";
export var body = "Text-module--body--aV7mN";
export var bodyNumeric = "Text-module--bodyNumeric--WXHds";
export var titleSmall = "Text-module--titleSmall--Tj6Ns";
export var bodySmall = "Text-module--bodySmall--tpP7y";
export var bodyNumericSmall = "Text-module--bodyNumericSmall--Rvmxm";
export var titleXSmall = "Text-module--titleXSmall--7KwmM";
export var bodyXSmall = "Text-module--bodyXSmall--OIFTK";
export var bodyNumericXSmall = "Text-module--bodyNumericXSmall--AhNBH";
export var label = "Text-module--label--Wi8Ll";
export var white = "Text-module--white--moqQT";
export var red = "Text-module--red--NJYsm";
export var blueLight = "Text-module--blueLight--2AcKM";
export var blue = "Text-module--blue--JIUaK";
export var blueDark = "Text-module--blueDark--JBOxS";
export var green = "Text-module--green--6u9eo";
export var grayLight = "Text-module--grayLight--mzeZL";
export var gray = "Text-module--gray--mIVIO";
export var grayDark = "Text-module--grayDark--kGPmf";
export var black = "Text-module--black--KACGV";
export var weight400 = "Text-module--weight400--8TUwP";
export var weight500 = "Text-module--weight500--Ag9BV";