import * as React from 'react';

export const IconChevronUp: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg width={16} height={16} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.886l3.771 3.77.943-.942L8 5 3.286 9.714l.943.943L8 6.886z"
      fill="#9B9FA2"
    />
  </svg>
);
