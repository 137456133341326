import classNames from 'classnames';
import React, { forwardRef, InputHTMLAttributes, Ref } from 'react';

import { Text, TextColor, TextVariant } from '../../Text';
import * as styles from './Input.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  label?: string;
  labelSecondary?: JSX.Element;
  ref?: Ref<HTMLInputElement>;
  reset?: boolean;
}

export const Input: React.FC<Props> = forwardRef(
  (
    { error, label = null, labelSecondary = null, reset, ...rest },
    ref,
  ): JSX.Element => (
    <label>
      {!!label && (
        <div className={styles.label}>
          <Text tag="div" variant={TextVariant.BODY_SMALL}>
            {label}
          </Text>
          {labelSecondary}
        </div>
      )}

      <input
        className={classNames({
          [styles.input]: !reset,
          [styles.reset]: reset,
          [styles.error]: !!error,
        })}
        aria-invalid={error ? 'true' : 'false'}
        {...rest}
        {...{ ref }}
      />

      {!!error && (
        <Text
          className={styles.errorMessage}
          tag="div"
          role="alert"
          variant={TextVariant.BODY_SMALL}
          color={TextColor.RED}
        >
          {error}
        </Text>
      )}
    </label>
  ),
);
